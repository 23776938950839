import { Injectable } from '@angular/core';
//import {UserService} from "../services/user.service";
import { HttpClient, HttpHeaders, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,HttpResponse,HttpErrorResponse } from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { filter, switchMap, take,catchError, tap } from "rxjs/operators";
import { Router } from "@angular/router";
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
//import { of } from "rxjs/observable/of";
import { of } from 'rxjs';
import { Cookie } from 'ng2-cookies';
import {ToastNotificationService} from '../services/toast-notify.service';
import {AuthService} from '../services/auth.service';
import {TokenStorageService} from '../services/token-storage.service';
import { environment } from '../../../environments/environment';
declare var Swal: any;

@Injectable({
  providedIn: 'root'
})
export class JwtInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private router: Router,
    private toastNotifyService: ToastNotificationService,
    private _http: HttpClient, private authService: AuthService,
    private tokenService: TokenStorageService,) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    console.log('this.router.url>>>>>>>>> ',this.router.url)
    let grant_type = request.params.get("grant_type");
    console.log('grant_type>>>>>>>>>>>', grant_type)

    let modifiedRequest = request;
    if(this.router.url.indexOf('/oauth') != -1 || this.router.url.indexOf('?code=') != -1){
      console.log("***********skipping urls..");
    }else if (Cookie.get('access_token')) {
      console.log("***********adding Bearer header..");
      modifiedRequest = request.clone({
        setHeaders: {
          'Authorization': 'Bearer ' + Cookie.get('access_token'),
          'X-TenantID': this.tokenService.getTenantName(),
        },
      });
    }
    return next.handle(modifiedRequest).pipe(
      catchError((errorResponse:HttpErrorResponse) => {
        console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ',JSON.stringify(errorResponse))
        let errorCode = errorResponse.error.code;
        let errorMessage = errorResponse.error.message;
        if(errorCode == null || errorCode == undefined){
          errorCode = errorResponse.error.error;
        }
        if(errorMessage == null || errorMessage == undefined){
          errorMessage = errorResponse.error.error_description;
        }
        console.log("errorResponse>>>", errorResponse.status+' : '+errorCode+' : '+errorMessage)
        //"error":{"error":"invalid_token","error_description":"Access token expired
        switch (errorResponse.status) {
          case 401:            
            if(errorMessage !== null && errorMessage !== undefined 
                                  && errorMessage.indexOf('expired') !== -1){
              const isRefrsh =  confirm("Your Session is Expred. Do you want to Continue");
              if(isRefrsh) {                 
                return this.handle401Error(modifiedRequest, next);          
              }
            }else{
              console.log('!!!!!!401-Unauthorized -showing popup');
              if(errorMessage !== null && errorMessage !== undefined){
                this.handleErrorShwToast('Unauthorized-'+errorMessage);
              }else{
                errorMessage = 'Unauthorized. Please check your credentials.';
                this.handleErrorShwToast(errorMessage);
              }              
            }
            break;
          case 400:
            errorMessage = 'Bad request or Duplicate data. Please check your input.';
            this.handleErrorShwToast(errorMessage);
            break;          
          case 403:
            errorMessage = 'Forbidden. You don\'t have permission to access this resource.';
            break;
          case 404:
            errorMessage = 'Not Found. The resource you requested may not exist.';
            break;
          case 409:
            errorMessage = 'Conflict. The request could not be completed due to a conflict.';
            break;
          case 500:
            errorMessage = 'The server encountered an unexpected condition from server.';
            this.handleErrorShwToast(errorMessage);
            break;
          default:
            // Handle other error codes as needed
        }        
        return throwError(errorResponse);
      })
    );
  }


  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      //if (this.storageService.isLoggedIn()) {
        return this.authService.refreshToken().pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;
            this.refreshTokenSubject.next(token.access_token);

            Cookie.set("access_token", token.access_token); //, expireDate
            Cookie.set("refresh_token", token.refresh_token); //, expireDate 

            return next.handle(this.addToken(request, token.access_token));
          }),
          catchError((error) => {
            this.isRefreshing = false;
            return throwError(() => error);
          })
        );
    }else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    console.log('token>>>>>>>>>>>>>>',token);
    let request1 = request.clone({ setHeaders: {'Authorization': 'Bearer ' + token,}, });
    console.log(JSON.stringify(request1));
     return request1;
  }
  private handleErrorShwToast(errMessage: string) {
    this.toastNotifyService.showWarningWithTimeout(errMessage,'ERROR',10000);
  }
  /*private handleAuthError(err: any) {
    //alert(errMessage);
    Swal.fire(
      "",
      err.message
      , "warning" // error, info
    ).then((result) => {
      if (result.value) {
        console.log('----result.value---'+result.value);
        return of(err);
      }
      
    });
  }*/
}

/*export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
];*/

/******************Print HttpErrorResponse object**************/
/*{
  "headers": {
    "normalizedNames": {},
    "lazyUpdate": null
  },
  "status": 401,
  "statusText": "OK",
  "url": "http://localhost:8080/api/profile/username",
  "ok": false,
  "name": "HttpErrorResponse",
  "message": "Http failure response for http://localhost:8080/api/profile/username: 401 OK",
  "error": {
    "code": "invalid_token",
    "message": "Token has expired"
  }
}*/