import { Injectable } from '@angular/core';
import { HttpClient,HttpEvent,HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs';
import { IPagedResults } from '../../../services/mocks/mock.interfaces';
import { User } from '../../../models/user.model';
import { LoggerService } from '../../../shared/services/logger.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ParentDashService {

  constructor(private http: HttpClient,
              private logger: LoggerService) {
  }

  getUserProfile(): Observable<any> {  
    return this.http.get<any>(environment.apiBase+'/profile/username');
  }
  saveUserData(user: User): Observable<any> {  
    return this.http.post<any>(environment.apiBase+'/userdata/parent/save',user);
  }
  getUserData(): Observable<any> {  
    return this.http.get<any>(environment.apiBase+'/userdata');
  }

  testMethod(userName: string): Observable<any> {  
    return this.http.get<any>(environment.apiBase+'/parents/username/'+userName);
  }
}
