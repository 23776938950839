import { AppService } from './../../services/app.service';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(private appService: AppService) { }
  isCollapsed = true;
  tenant = '';
  clientLogo: string = '';

  ngOnInit() {
    let hostname = window.location.hostname; // get tenant i.e subdomain from "subdomain.domain.com"
    let tenant = 'DemoTenant'
    if(hostname == 'localhost'){
      tenant = environment.defaultTenant
    }else{
      tenant= hostname.substring(0, hostname.indexOf('.'));
    }
    console.log(">>>>>Host Name:: "+hostname+' :tenant:>>>'+tenant);
    //this.clientLogo = environment.clientLogoUrl+tenant;
    //console.log('this.clientLogo>>>>>>',this.clientLogo)
  }

  toggleSidebarPin() {
    this.appService.toggleSidebarPin();
  }
  toggleSidebar() {
    this.appService.toggleSidebar();
  }



}
