import { Component, OnInit } from '@angular/core';
import { Cookie } from 'ng2-cookies';
import { AppService } from './../../services/app.service';
import { UserService } from '../../shared/services/user.service';
import { User } from '../../models/user.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ParentDashService } from '../../pages/parent-dash/services/parent-dash.service';
import { AuthService } from '../../shared/services/auth.service';
import { TokenStorageService } from '../../shared/services/token-storage.service';
import {ToastNotificationService} from '../../shared/services/toast-notify.service';
import { environment } from '../../../environments/environment'

@Component({
  selector: 'app-sidebar-parent',
  templateUrl: './sidebar-parent.component.html',
  styleUrls: ['./sidebar-parent.component.scss']
})
export class SidebarParentComponent implements OnInit {

  userFullName: string;
  userRole: string;
  lastLoggedIn: string;
  dateTimeFormat: string;
  userProfile: User;

  salesPipilineMenu: boolean;
  settingMenu: boolean;
  masterMenu: boolean;
  testMenu: boolean;
  notificationMenu: boolean;
  studentMenu: boolean;
  reportMenu: boolean;
  teacherMenu: boolean;

  constructor(private appService: AppService,
              private userService: UserService,
              private parentDashService: ParentDashService,
              private toastNotifyService: ToastNotificationService, 
              private tokenStorageService: TokenStorageService) { }


  ngOnInit() {
    console.log('***ngOnInit: sidebar-admin');
    // Clear the cookie & local
    //this.deleteSpecificCookies();
    this.salesPipilineMenu = false;
    this.settingMenu = false;
    this.masterMenu = false;
    this.testMenu = false;
    this.notificationMenu = false;
    this.studentMenu = false;
    this.reportMenu = false;
    this.teacherMenu = false;
    this.dateTimeFormat = environment.dtFormatDefault
    this.getUserData();   
  }

  getUserData(){
    console.log('>>>>>getUserData- sidebar-admin>>>>> ')
    this.parentDashService.getUserData().subscribe({
      next: (response: any) => {
        console.log(">>>getUserData from service:sidebar parent- "+JSON.stringify(response));
        if(response.status == environment.SUCCESS){
          this.userProfile =  response.data;
        }else{
          // Get from cookie beacuse it happens for first time
          const userData: User = {
            userName: this.tokenStorageService.getUser(),userRole: this.tokenStorageService.getUserRole(),
            clientName: this.tokenStorageService.getTenantName(), ownerName: this.tokenStorageService.getOwnerName()}
          
          this.userProfile = userData;
          //this.toastNotifyService.showErrorWithTimeout(response.message, "Notification", 7000);
        }           
      },
      error: (e) => {
        console.error(e);          
      }
    });
  }

  toggleFlag(category: string, value: boolean){
    console.log('category: '+category+': value: '+value);
    var toggle = value ? false : true;
    //return toggle;
    if('sales_pipeline' == category){
      this.salesPipilineMenu = toggle;
    }else if('setting' == category){
      console.log('--------------Setting Menu------------')
      this.settingMenu = toggle;
    }else if('master' == category){
      console.log('--------------Master Menu------------')
      this.masterMenu = toggle;
    }else if('test_exam' == category){
      this.testMenu = toggle;
    }else if('notification' == category){
      this.notificationMenu = toggle;
    }else if('student' == category){
      this.studentMenu = toggle;
    }else if('report' == category){
      this.reportMenu = toggle;
    }else if('teacher' == category){
      this.teacherMenu = toggle;
    }
  }

  deleteSpecificCookies(){
    Cookie.delete("org_id");
    Cookie.delete("org_name");
    Cookie.delete("dtFormat");
    console.log('***specific cookies deleted***');
  }

}
