<nav class="navbar navbar-expand-md fixed-top navbar-dark flex-fill">
  <!-- <button class="sidebar-toggler ml-4" type="button">
    <i class="fa fa-navicon"></i>
  </button> -->
  <!-- header -->
  <div class="navbar-header pl-2 pr-2 ml-3 text-center">
    <a class="navbar-brand m-0 w-100" href="#">
      <!-- <i class="fa fa-flash mr-1"></i> -->
      <img src="./assets/img/nexsharp_red.png" alt="nexrpa logo" width="60px"><!--logo_cloud-->
      <!--<span class="ml-1 text-uppercase text-orange-bold">&nbsp;NexRPA</span>-->
      <span class="ml-1">
        <img src="./assets/img/nexrpa.png" alt="nexrpa logo" width="150px">
      </span>
      
      <!--<span class="crumb3 text-white" >     
        <li style="float:left;">SmartBOT</li>
    </span>   -->
    </a>
  </div>
  <!-- sidebar toggler -->
  <div class="sidebar-toggler ml-auto mr-3">
    <a class="btn nav-link" (click)="toggleSidebar()"><i class="fa fa-bars"></i></a>
  </div>
  <!-- right menu toggler -->
  <div class="nav-toggler-right mr-4 d-md-none">
    <button class="" type="button" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
      aria-controls="collapseBasic">
      <!-- <span class="navbar-toggler-icon"></span> -->profilePicUrl
      <img src="./assets/img/user.jpg" class="img-fluid" alt="">
      
    </button>
    <span class="user-profile nav-alert bg-danger"></span>
  </div>
  <!-- left box -->
  <ul class="navbar-nav flex-fill mt-1 align-items-center left-nav">
    <li class="nav-item navicon">
      <a class="btn nav-link" (click)="toggleSidebarPin()"><span class="fa fa-bars" style="font-size:20px;"></span></a>
    </li>
    <!--<li class="nav-item flex-fill">
      <input class="form-control navbar-search" type="text" placeholder="Search . . .">
    </li>-->
    <span class="p-2 mb-0 role text-blue-bold font-weight-bold">Organization: </span><span class="text-orange-bold">{{orgName}}</span>
  </ul>

  <!-- right menu -->
  <div class="collapse navbar-collapse right-nav" id="collapseBasic" [collapse]="isCollapsed">
    <ul class="navbar-nav">

      
      <li class="nav-item">
        <a class="btn nav-link">
          <span class="far fa-bell" style="font-size:20px;"></span>
          <span class="link-text">Alerts</span>
          <span class="badge badge-pill ba dge-danger">3</span>
          <span class="nav-alert notifications"></span>
        </a>
      </li>

      
      <li class="nav-item">
        <a class="btn nav-link">
          <span class="far fa-envelope" style="font-size:20px;"></span>
          <span class="link-text">Messages</span>
          <span class="badge badge-pill badge-success">5</span>
          <span class="nav-alert messages"></span>
        </a>
      </li>
      <li class="nav-item">
        <a class="btn nav-link">
          <span class="far fa-user" style="font-size:20px;"></span>
          <span class="link-text">Profile</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="btn nav-link">
          <span class="fas fa-cog" style="font-size:20px;"></span>
          <span class="link-text">Setting</span>
        </a>
      </li>
      <li class="btn nav-item dropdown">
        <a  class="btn nav-link font-weight-bold" data-bs-toggle="dropdown">
          <!--src="assets/img/user.jpg"-->
          <img
            src={{profilePicUrl}}
            alt="user"
            class="rounded-circle"
            width="32"
          />
          <!--Nila-->
        </a>
        <div class="dropdown-menu dropdown-menu-right">
          <a routerLink="/parent/profile" class="dropdown-item">My Profile</a>
          <a routerLink="/parent/dashboard" class="dropdown-item">Inbox</a>
          <a routerLink="/parent/dashboard" class="dropdown-item">Accout Setting</a>
          <div class="dropdown-divider"></div>
          <a routerLink="/parent/logout" class="dropdown-item">Logout</a>
        </div>
      </li>

      <li class="nav-item">
        <a routerLink="/parent/logout" class="btn nav-link font-weight-bold" routerLinkActive="active"> 
          Logout
        </a>
      </li>

      <!--<li class="nav-item dropdown">
        <a class="btn nav-link" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="far fa-user"></i>
          <span class="link-text">Profile</span>
        </a>
        <div class="dropdown-menu" style="background-color: orange">
          <a routerLink="/user/dashboard" class="dropdown-item">My Profile</a>
          <a routerLink="/user/dashboard" class="dropdown-item">Inbox</a>
          <a routerLink="/user/dashboard" class="dropdown-item">Accout Setting</a>
          <div class="dropdown-divider"></div>
          <a routerLink="/user/logout" class="dropdown-item">Logout</a>
        </div>
      </li>

      <li class="nav-item dropdown">
        <div class="btn-group">
          <button type="button" data-bs-toggle="dropdown" class="btn-custom p-2 dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="far fa-user">&nbsp;Profile</i>
          </button>
          <div class="dropdown-menu">
            <a routerLink="/user/dashboard" class="dropdown-item">My Profile</a>
            <a routerLink="/user/dashboard" class="dropdown-item">Inbox</a>
            <a routerLink="/user/dashboard" class="dropdown-item">Accout Setting</a>
            <div class="dropdown-divider"></div>
            <a routerLink="/user/logout" class="dropdown-item">Logout</a>
          </div>
        </div>
      </li>
      &nbsp;
      <li class="nav-item dropdown">
        <div class="btn-group">
          <button type="button" data-bs-toggle="dropdown" class="btn-custom p-2 dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Action
          </button>
          <div class="dropdown-menu">
            <a routerLink="/user/dashboard" class="dropdown-item">My Profile</a>
            <a routerLink="/user/dashboard" class="dropdown-item">Inbox</a>
            <a routerLink="/user/dashboard" class="dropdown-item">Accout Setting</a>
            <div class="dropdown-divider"></div>
            <a routerLink="/user/logout" class="dropdown-item">Logout</a>
          </div>
        </div>
      </li>
      
      <li class="btn nav-item dropdown">
        <a  class="btn nav-link font-weight-bold" data-bs-toggle="dropdown">
          Messages
        </a>
        <div class="dropdown-menu">
          <a routerLink="/user/dashboard" class="dropdown-item">My Profile</a>
          <a routerLink="/user/dashboard" class="dropdown-item">Inbox</a>
          <a routerLink="/user/dashboard" class="dropdown-item">Accout Setting</a>
          <div class="dropdown-divider"></div>
          <a routerLink="/user/logout" class="dropdown-item">Logout</a>
        </div>
      </li>-->
    </ul>
  </div>



</nav>