import { Injectable } from '@angular/core';
import { HttpClient,HttpEvent,HttpRequest } from '@angular/common/http';
import { User } from '../../models/user.model';
import { GenericModel } from '../../models/generic.model';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs';
import { LoggerService } from './logger.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient,
              private logger: LoggerService) {
  }
  /*================Actual Servces call==================================*/
  updateUserOnLogin(userData: User): Observable<GenericModel<User>> {
    //debugger
    console.log("inside updateUserOnLogin..."+JSON.stringify(userData));
    return this.http.post<GenericModel<User>>(environment.apiBase+'/user/updateUserOnLogin', userData);
  }

  getUserActivity(): Observable<GenericModel<User>> { 
    return this.http.get<GenericModel<User>>(environment.apiBase+'/user/getUserActivity');
  }
  
  checkFirstTimeLogin(): Observable<any> { 
    return this.http.get<any>(environment.apiBase+'/user/checkFirstTimeLogin');
  }

  checkOrganizationForUser(): Observable<any> { 
    return this.http.get<any>(environment.apiBase+'/organization/checkOrganizationForUser');
  }

}
