<nav class="navbar navbar-expand-md fixed-top navbar-dark flex-fill">
  <!-- <button class="sidebar-toggler ml-4" type="button">
    <i class="fa fa-navicon"></i>
  </button> -->
  <!-- header -->
  <div class="navbar-header pl-2 pr-2 ml-3 text-center">
    <a class="navbar-brand m-0 w-100" href="#">
      <!-- <i class="fa fa-flash mr-1"></i> -->      
      <img src="./assets/img/nexsharp1.png" alt="nexrpa logo" width="200px">
      <span><img src={{clientLogo}} alt="nexrpa logo" width="200px"></span>
      
    </a>
  </div>
  <!-- sidebar toggler -->
  <!--<div class="sidebar-toggler ml-auto mr-3">
    <a class="btn nav-link" (click)="toggleSidebar()"><i class="fa fa-bars"></i></a>
  </div>-->
  
  <!-- left box -->
  <!--<ul class="navbar-nav flex-fill mt-1 align-items-center left-nav">
    <li class="nav-item navicon">
      <a class="btn nav-link" (click)="toggleSidebarPin()"><span class="fa fa-bars" style="font-size:20px;"></span></a>
    </li>
  </ul>-->


  <!-- right menu toggler -->
  <div class="nav-toggler-right mr-4 d-md-none">
    <button class="" type="button" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
      aria-controls="collapseBasic">
      <img src="./assets/img/user.jpg" class="img-fluid" alt="">
    </button>
    <span class="user-profile nav-alert bg-danger"></span>
  </div>
  
  <!--<span><img src="./assets/img/make-in-india.png" alt="Make in India" width="120px"></span>-->
  
  <!-- right menu -->
  <div class="collapse navbar-collapse right-nav" id="collapseBasic" [collapse]="isCollapsed">
    <ul class="navbar-nav ">

      
      <li class="nav-item">
        <a routerLink="/about" class="btn nav-link font-weight-bold" routerLinkActive="active">
          Sample
        </a>
      </li>
      <li class="nav-item">
        <a routerLink="/about" class="btn nav-link font-weight-bold" routerLinkActive="active">
          About
        </a>
      </li>
      
      <li class="btn nav-item dropdown">
        <a class="btn nav-link font-weight-bold" data-bs-toggle="dropdown" routerLinkActive="active">
          Products
        </a>
        <div class="dropdown-menu dropdown-menu-right"> <!-- right is to keep left just reverse-->
          <a routerLink="/user/logout" class="dropdown-item">Task Bot</a>
          <a routerLink="/user/logout" class="dropdown-item">IQ Bot</a>
          <div class="dropdown-divider"></div>
          <a routerLink="/user/logout" class="dropdown-item">Logout</a>
        </div>
      </li>

      <!--<li class="nav-item">
        <a routerLink="/solutions" class="btn nav-link font-weight-bold" routerLinkActive="active"> 
          Solutions
        </a>
      </li>-->
      <!--https://www.w3schools.com/howto/howto_css_dropdown.asp-->

      <li class="dropdown" style="float:right;">
        <a class="btn nav-link font-weight-bold">Solutions</a>
        <div class="dropdown-content" style="right:0;">
          <a href="#">Link 1</a>
          <a href="#">Link 2</a>
          <a href="#">Link 3</a>
        </div>
      </li>
      <!--<div class="dropdown">
        <button class="dropbtn">Dropdown</button>
        <div class="dropdown-content">
          <a href="#">Link 1</a>
          <a href="#">Link 2</a>
          <a href="#">Link 3</a>
        </div>
      </div>-->
      
      <li class="btn nav-item dropdown">
        <a class="btn nav-link font-weight-bold" data-bs-toggle="dropdown" routerLinkActive="active">
          Partners
        </a>
        <div class="dropdown-menu dropdown-menu-right"> <!-- right is to keep left just reverse-->
          <a routerLink="/user/logout" class="dropdown-item">Become Partner</a>
          <a routerLink="/user/logout" class="dropdown-item">Search Partners</a>
        </div>
      </li>

      <li class="nav-item">
        <a routerLink="/pricing" class="btn nav-link font-weight-bold" routerLinkActive="active">
          Pricing
        </a>
      </li>
      <li class="nav-item">
        <a routerLink="/download" class="btn nav-link font-weight-bold" routerLinkActive="active"> 
          Download
        </a>
      </li>

      <li class="nav-item">
        <a routerLink="/casestudy" class="btn nav-link font-weight-bold" routerLinkActive="active">
          CaseStudy
        </a>
      </li>

      <li class="nav-item">
        <a routerLink="/login-oauth" class="btn nav-link font-weight-bold" routerLinkActive="active"> 
          Login
        </a>
      </li>


      
      <!--<li class="nav-item">
        <a class="btn nav-link">
          <i class="far fa-bell"></i>
          <span class="link-text">Alerts</span>
          <span class="badge badge-pill ba dge-danger">3</span>
          <span class="nav-alert notifications"></span>
        </a>
      </li>

      
      <li class="nav-item">
        <a class="btn nav-link">
          <i class="far fa-envelope"></i>
          <span class="link-text">Messages</span>
          <span class="badge badge-pill badge-success">5</span>
          <span class="nav-alert messages"></span>
        </a>
      </li>
      <li class="nav-item">
        <a class="btn nav-link">
          <i class="far fa-user"></i>
          <span class="link-text">Profile</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="btn nav-link">
          <i class="fas fa-cog"></i>
          <span class="link-text">Setting</span>
        </a>
      </li>-->

    </ul>
  </div>



</nav>