import { NgModule } from '@angular/core';
import { RouterModule, Routes} from '@angular/router';
import { HomeLayoutComponent } from './layout/home/home-layout.component';
import { AdminLayoutComponent } from './layout/admin/admin-layout.component';
import { AdminDashComponent } from './pages/admin-dash/admin-dashboard.component';
import { StudentLayoutComponent } from './layout/student/student-layout.component';
import { StudentDashComponent } from './pages/student-dash/student-dashboard.component';
import { AdminSampleComponent } from './pages/admin-sample/admin-sample.component';
import { LogoutComponent } from './shared/logout/logout.component';
import { ParentLayoutComponent } from './layout/parent/parent-layout.component';
import { ParentDashComponent } from './pages/parent-dash/parent-dashboard.component';
import { TeacherLayoutComponent } from './layout/teacher/teacher-layout.component';
import { TeacherDashComponent } from './pages/teacher-dash/teacher-dashboard.component';

const routes: Routes = [
  //{ path: '', redirectTo: 'tutorials', pathMatch: 'full' },
  {
    path: '',
    component: HomeLayoutComponent,
    children: [
      { 
        path: '', 
        //component: LandingPageComponent, pathMatch: 'full'},
        loadChildren: () => import('src/app/layout/home/home-layout.module').then(m => m.HomeLayoutModule)
      },
    ]
  },  
  { 
    path: 'admin', 
    component: AdminLayoutComponent, 
    //canActivate: [AuthGuardAdmin],
    children: [
      {path: '',  redirectTo: 'dashboard', pathMatch: 'full'},
      { path: 'dashboard',  component: AdminDashComponent },
      //{ path: 'sample',     component: AdminSampleComponent },
      { path: 'sample', loadChildren: () => import('./pages/sample/sample.module').then(m => m.SampleModule)},
      { path: 'branch', loadChildren: () => import('./pages/branch/branch.module').then(m => m.BranchModule)},
      { path: 'template', loadChildren: () => import('./pages/template/template.module').then(m => m.TemplateModule)},
      { path: 'setting', loadChildren: () => import('./pages/setting/setting.module').then(m => m.SettingModule)},
      { path: 'parent', loadChildren: () => import('./pages/parent/parent.module').then(m => m.ParentModule)},
      { path: 'addStudent', loadChildren: () => import('./pages/add-student/add-student.module').then(m => m.AddStudentModule)},
      { path: 'students', loadChildren: () => import('./pages/student-detail/student-detail.module').then(m => m.StudentDetailModule)},
      { path: 'useraccess', loadChildren: () => import('./pages/user-access/user-access.module').then(m => m.UserAccessModule)},
      { path: 'busroute', loadChildren: () => import('./pages/bus-route/busroute.module').then(m => m.BusRouteModule)},
      { path: 'vehicle', loadChildren: () => import('./pages/vehicle/vehicle.module').then(m => m.VehicleModule)},
      { path: 'session', loadChildren: () => import('./pages/session/session.module').then(m => m.SessionModule)},
      { path: 'board', loadChildren: () => import('./pages/board/board.module').then(m => m.BoardModule)},
      { path: 'stream', loadChildren: () => import('./pages/stream/stream.module').then(m => m.StreamModule)},
      { path: 'classcourse', loadChildren: () => import('./pages/classcourse/classcourse.module').then(m => m.ClassCourseModule)},
      { path: 'section', loadChildren: () => import('./pages/section/section.module').then(m => m.SectionModule)},
      { path: 'subject', loadChildren: () => import('./pages/subject/subject.module').then(m => m.SubjectModule)},
      { path: 'notice', loadChildren: () => import('./pages/notice/notice.module').then(m => m.NoticeModule)},
      { path: 'attendance', loadChildren: () => import('./pages/attendance/attendance.module').then(m => m.AttendanceModule)},
      { path: 'timetable', loadChildren: () => import('./pages/timetable/timetable.module').then(m => m.TimeTableModule)},
      { path: 'leave', loadChildren: () => import('./pages/leave/leave.module').then(m => m.LeaveModule)},
      { path: 'leavetype', loadChildren: () => import('./pages/leavetype/leavetype.module').then(m => m.LeaveTypeModule)},
      { path: 'holiday', loadChildren: () => import('./pages/holiday/holiday.module').then(m => m.HolidayModule)},
      { path: 'department', loadChildren: () => import('./pages/department/department.module').then(m => m.DepartmentModule)},
      { path: 'designation', loadChildren: () => import('./pages/designation/designation.module').then(m => m.DesignationModule)},
      { path: 'fee', loadChildren: () => import('./pages/fee/fee.module').then(m => m.FeeModule)},
      { path: 'homework', loadChildren: () => import('./pages/homework/homework.module').then(m => m.HomeWorkModule)},
      { path: 'staff', loadChildren: () => import('./pages/staff/staff.module').then(m => m.StaffModule)},
      { path: 'questionbank', loadChildren: () => import('./pages/questionbank/questionbank.module').then(m => m.QuestionBankModule)},
      { path: 'onlineexam', loadChildren: () => import('./pages/online-exam/online-exam.module').then(m => m.OnlineExamModule)},
      
      

      { path: 'logout',       component: LogoutComponent }, 
    ]
  },
  { 
    path: 'student', 
    component: StudentLayoutComponent, 
    //canActivate: [AuthGuardAdmin],
    children: [
      {path: '',  redirectTo: 'dashboard', pathMatch: 'full'},
      { path: 'dashboard',  component: StudentDashComponent },  
      { path: 'logout',       component: LogoutComponent },     
    ]
  },
  { 
    path: 'parent', 
    component: ParentLayoutComponent, 
    //canActivate: [AuthGuardAdmin],
    children: [
      {path: '',  redirectTo: 'dashboard', pathMatch: 'full'},
      { path: 'dashboard',  component: ParentDashComponent },     
      { path: 'logout',       component: LogoutComponent },  
    ]
  },
  { 
    path: 'teacher', 
    component: TeacherLayoutComponent, 
    //canActivate: [AuthGuardAdmin],
    children: [
      {path: '',  redirectTo: 'dashboard', pathMatch: 'full'},
      { path: 'dashboard',  component: TeacherDashComponent },     
      { path: 'logout',       component: LogoutComponent },  
    ]
  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }