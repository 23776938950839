import { Component, HostListener,OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../../services/app.service';
import { UserService } from '../../shared/services/user.service';
import { User } from 'src/app/models/user.model';
import { GenericModel } from 'src/app/models/generic.model'
import { Subject } from 'rxjs';

@Component({
  selector: 'app-parent-layout',
  templateUrl: './parent-layout.component.html',
  styleUrls: ['./parent-layout.component.scss']
})
export class ParentLayoutComponent implements OnInit {

  userDetail: GenericModel<User>;
  userActivity: any;
  userInactive: Subject<any> = new Subject();


  constructor(private router: Router,private appService:AppService,
              private userService: UserService,) {                
               }

  getClasses() {
    const classes = {
      'pinned-sidebar': this.appService.getSidebarStat().isSidebarPinned,
      'toggeled-sidebar': this.appService.getSidebarStat().isSidebarToggeled
    }
    return classes;
  }
  toggleSidebar() {
    this.appService.toggleSidebar();
  }

  ngOnInit() {
    console.log("***inside parent layout****");
    //this.updateUserOnLogin();
  }
}