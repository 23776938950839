<aside class="sidebar shadow-sm">

  <div class="sidebar-profile d-flex flex-column justify-content-center pt-2 pb-2">
    <!-- Profile with photo starts-->
    <!--<div class="picture-wrapper mb-2">
      <div class="user-picture m-auto">
        <img src="./assets/img/user.jpg" class="img-fluid" alt="">
      </div>
    </div>-->
    
    <div class="profile-details text-center pb-2">
      <p class="mb-0 name">{{userProfile.ownerName}}</p>
      <p class="mb-0 name" style="color:rgb(228, 133, 25);">{{userProfile.userRole}}</p>
      <span class="role">Last Login: {{userProfile.lastLogin | date: dateTimeFormat}}</span>
    </div>
    
    <!-- Profile with photo ends-->
    
  </div>
  <div class="sidebar-search pt-1 pb-1 pl-3 pr-3">
    <input type="search" class="form-control rounded-pill" name="search" placeholder="Search ...">
  </div>
  <nav class="sidebar-menu pt-1 pb-1">
    <!--<div class="menu-header mt-3 mb-2">
      Components
    </div>-->
    <ul class="menu-items">
      <li >
        <a href="#" routerLink="/admin/dashboard" routerLinkActive="active">
          <!--<i class="fas fa-home" style="font-size:18px"></i>-->
          <i class="material-icons" style="font-size:20px">dashboard</i>
          <span >Dashboard</span>
          <!--<span class="badge badge-warning rounded-0 text-dark p-1">NEW</span>-->
        </a>
      </li>
      <li >
        <a href="#" routerLink="/admin/branch" routerLinkActive="active">
          <i class="fas fa-hdd" style="font-size:20px"></i>
          <span>Branches**</span>          
        </a>
      </li> 
      <li >
        <a href="#" routerLink="/admin/template" routerLinkActive="active">
          <i class="fas fa-hdd" style="font-size:20px"></i>
          <span>Templates**</span>          
        </a>
      </li>
      <li >
        <a href="#" routerLink="/admin/sample" routerLinkActive="active">
          <i class="fas fa-hdd" style="font-size:20px"></i>
          <span>Samples</span>          
        </a>
      </li>

      <!------------------Global Setting Menu Category----------------------->
      <li >
        <a routerLinkActive="active">
          <i class="fas fa-user-cog" style="font-size:20px"></i>
          <span style="cursor: pointer;" (click)="toggleFlag('setting',settingMenu)">Global Setting</span>
          <i *ngIf="!settingMenu" (click)="toggleFlag('setting',settingMenu)" class="fa fa-plus ml-auto" style="cursor: pointer; font-size:12px; color:rgb(228, 133, 25);"></i>
          <i *ngIf="settingMenu" (click)="toggleFlag('setting',settingMenu)" class="fa fa-minus ml-auto" style="cursor: pointer; font-size:12px; color:rgb(228, 133, 25);"></i>
        </a> 
      </li>
      <span *ngIf="settingMenu">  
        <li>
          <a href="#" routerLink="/admin/setting" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">General Setting*</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/admin/setting" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Payment Gateway</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/admin/setting" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">SMS Setting</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/admin/setting" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Email Setting</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/admin/setting" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Modules</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/admin/useraccess" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">User Permission**</span>        
          </a>
        </li> 
      </span>  
      <!------------------Payroll Master Menu Category----------------------->
      <li >
        <a routerLinkActive="active">
          <i class="fas fa-user-cog" style="font-size:20px"></i>
          <span style="cursor: pointer;" (click)="toggleFlag('feeMgmt',feeMgmtMenu)">Fee Management</span>
          <i *ngIf="!feeMgmtMenu" (click)="toggleFlag('feeMgmt',feeMgmtMenu)" class="fa fa-plus ml-auto" style="cursor: pointer; font-size:12px; color:rgb(228, 133, 25);"></i>
          <i *ngIf="feeMgmtMenu" (click)="toggleFlag('feeMgmt',feeMgmtMenu)" class="fa fa-minus ml-auto" style="cursor: pointer; font-size:12px; color:rgb(228, 133, 25);"></i>
        </a> 
      </li>
      <span *ngIf="feeMgmtMenu">        
        <li>
          <a href="#" routerLink="/user/leads" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Fee Group/Slab</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/admin/fee" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Fee Type</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/admin/fee/setup" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Fee Master/Assign</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/admin/fee/details" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Fee Details</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/user/leads" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Fee Discount</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/user/leads" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Collect Fee/Update</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/user/leads" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Search Fee</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/user/leads" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Fee Carry Forward</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/user/leads" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Fee Reminder</span>        
          </a>
        </li>
      </span>
      <!------------------Payroll Master Menu Category----------------------->
      <li >
        <a routerLinkActive="active">
          <i class="fas fa-user-cog" style="font-size:20px"></i>
          <span style="cursor: pointer;" (click)="toggleFlag('payrollMgmt',payrollMgmtMenu)">Payroll Management</span>
          <i *ngIf="!payrollMgmtMenu" (click)="toggleFlag('payrollMgmt',payrollMgmtMenu)" class="fa fa-plus ml-auto" style="cursor: pointer; font-size:12px; color:rgb(228, 133, 25);"></i>
          <i *ngIf="payrollMgmtMenu" (click)="toggleFlag('payrollMgmt',payrollMgmtMenu)" class="fa fa-minus ml-auto" style="cursor: pointer; font-size:12px; color:rgb(228, 133, 25);"></i>
        </a> 
      </li>
      <span *ngIf="payrollMgmtMenu">        
        <li>
          <a href="#" routerLink="/user/leads" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Add Allownce</span>        
          </a>
        </li> 
        <li>
          <a href="#" routerLink="/user/leads" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Add Deduction</span>        
          </a>
        </li> 
        <li>
          <a href="#" routerLink="/user/leads" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Leave Rule</span>        
          </a>
        </li> 
        <li>
          <a href="#" routerLink="/user/leads" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Generate Payroll</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/user/leads" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">View Payroll</span>        
          </a>
        </li> 
      </span>
      <!------------------Masterdata Menu Category----------------------->
      <li >
        <a routerLinkActive="active">
          <i class="fas fa-user-cog" style="font-size:20px"></i>
          <span style="cursor: pointer;" (click)="toggleFlag('master',masterMenu)">Master Data</span>
          <i *ngIf="!masterMenu" (click)="toggleFlag('master',masterMenu)" class="fa fa-plus ml-auto" style="cursor: pointer; font-size:12px; color:rgb(228, 133, 25);"></i>
          <i *ngIf="masterMenu" (click)="toggleFlag('master',masterMenu)" class="fa fa-minus ml-auto" style="cursor: pointer; font-size:12px; color:rgb(228, 133, 25);"></i>
        </a> 
      </li>
      <span *ngIf="masterMenu">
        <li>
          <a href="#" routerLink="/admin/branch" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Institute Branch**</span>        
          </a>
        </li> 
        <li>
          <a href="#" routerLink="/admin/session" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Sessions**</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/admin/board" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Boards and Exams**</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/admin/stream" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Streams**</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/admin/classcourse" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Classe or Course**</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/admin/section" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Section or Batch**</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/admin/subject" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Subjects**</span>        
          </a>
        </li> 
        <li>
          <a href="#" routerLink="/admin/timetable" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Time Table**</span>        
          </a>
        </li> 
        <li>
          <a href="#" routerLink="/admin/homework" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Home Work**</span>        
          </a>
        </li> 
        <li>
          <a href="#" routerLink="/admin/leavetype" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Leave Type**</span>        
          </a>
        </li> 
        <li>
          <a href="#" routerLink="/admin/holiday" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Holiday**</span>        
          </a>
        </li> 
        <li>
          <a href="#" routerLink="/admin/department" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Department**</span>        
          </a>
        </li> 
        <li>
          <a href="#" routerLink="/admin/designation" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Designation**</span>        
          </a>
        </li> 
        <li>
          <a href="#" routerLink="/user/leads" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Lessions</span>        
          </a>
        </li> 
        <li>
          <a href="#" routerLink="/user/leads" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Topics</span>        
          </a>
        </li> 
        <li>
          <a href="#" routerLink="/user/leads" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Lession Plan</span>        
          </a>
        </li> 
        <li>
          <a href="#" routerLink="/user/leads" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Home Work</span>        
          </a>
        </li> 
         
        <li>
          <a href="#" routerLink="/user/template" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Templates</span>        
          </a>
        </li> 
      </span>
      <!------------------AUTOMATIONS Menu Category----------------------->
      <li >
        <a routerLinkActive="active">
          <i class="fas fa-user-cog" style="font-size:20px"></i>
          <span style="cursor: pointer;" (click)="toggleFlag('student',studentMenu)">Students</span>
          <i *ngIf="!studentMenu" (click)="toggleFlag('student',studentMenu)" class="fa fa-plus ml-auto" style="cursor: pointer; font-size:12px; color:rgb(228, 133, 25);"></i>
          <i *ngIf="studentMenu" (click)="toggleFlag('student',studentMenu)" class="fa fa-minus ml-auto" style="cursor: pointer; font-size:12px; color:rgb(228, 133, 25);"></i>
        </a> 
      </li>
      <span *ngIf="studentMenu">  
        <li>
          <a href="#" routerLink="/admin/addStudent" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">New Student*</span>        
          </a>
        </li>       
        <li>
          <a href="#" routerLink="/admin/students" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Student Details*</span>        
          </a>
        </li>  
        
        <li>
          <a href="#" routerLink="/user/tracker" routerLinkActive="active">
            &nbsp;&nbsp;<i class="fa fa-cog" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Scores and Analytics</span>         
          </a>
        </li>
        <li>
          <a href="#" routerLink="/user/tracker" routerLinkActive="active">
            &nbsp;&nbsp;<i class="fa fa-cog" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Certificates</span>         
          </a>
        </li>
        <li>
          <a href="#" routerLink="/user/tracker" routerLinkActive="active">
            &nbsp;&nbsp;<i class="fa fa-cog" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Identity Card</span>         
          </a>
        </li>
        <li>
          <a href="#" routerLink="/user/tracker" routerLinkActive="active">
            &nbsp;&nbsp;<i class="fa fa-cog" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Exam Admit Card</span>         
          </a>
        </li>
        <li>
          <a href="#" routerLink="/user/tracker" routerLinkActive="active">
            &nbsp;&nbsp;<i class="fa fa-cog" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Class Promotion</span>         
          </a>
        </li>
      </span>
      <!------------------AUTOMATIONS Menu Category----------------------->
      <!------------------Student Attendance Menu Category----------------------->
      <li >
        <a routerLinkActive="active">
          <i class="fas fa-user-cog" style="font-size:20px"></i>
          <span style="cursor: pointer;" (click)="toggleFlag('attendance',attendanceMenu)">Student Attendance**</span>
          <i *ngIf="!attendanceMenu" (click)="toggleFlag('attendance',attendanceMenu)" class="fa fa-plus ml-auto" style="cursor: pointer; font-size:12px; color:rgb(228, 133, 25);"></i>
          <i *ngIf="attendanceMenu" (click)="toggleFlag('attendance',attendanceMenu)" class="fa fa-minus ml-auto" style="cursor: pointer; font-size:12px; color:rgb(228, 133, 25);"></i>
        </a> 
      </li>
      <span *ngIf="attendanceMenu">        
        <li>
          <a href="#" routerLink="/admin/attendance" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Attendance**</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/admin/attendance/view" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">View Attendance**</span>        
          </a>
        </li>
        
        <li>
          <a href="#" routerLink="/admin/leave" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Leave Requests</span>        
          </a>
        </li>
      </span>
      <!------------------Student Attendance Menu Category----------------------->
      <li >
        <a routerLinkActive="active">
          <i class="fas fa-user-cog" style="font-size:20px"></i>
          <span style="cursor: pointer;" (click)="toggleFlag('crm',crmMenu)">CRM & Enquiry**</span>
          <i *ngIf="!crmMenu" (click)="toggleFlag('crm',crmMenu)" class="fa fa-plus ml-auto" style="cursor: pointer; font-size:12px; color:rgb(228, 133, 25);"></i>
          <i *ngIf="crmMenu" (click)="toggleFlag('crm',crmMenu)" class="fa fa-minus ml-auto" style="cursor: pointer; font-size:12px; color:rgb(228, 133, 25);"></i>
        </a> 
      </li>
      <span *ngIf="crmMenu">        
        <li>
          <a href="#" routerLink="/admin/vehicle" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Admission Enquiry**</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/admin/busroute" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Visitors**</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/admin/busroute" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Phone Calls**</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/admin/busroute" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Postal*</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/admin/busroute" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Complains**</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/admin/busroute" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Master Data**</span>        
          </a>
        </li>
      </span>
      <!------------------Transport Menu Category----------------------->
      <li >
        <a routerLinkActive="active">
          <i class="fas fa-user-cog" style="font-size:20px"></i>
          <span style="cursor: pointer;" (click)="toggleFlag('transport',transportMenu)">Transport**</span>
          <i *ngIf="!transportMenu" (click)="toggleFlag('transport',transportMenu)" class="fa fa-plus ml-auto" style="cursor: pointer; font-size:12px; color:rgb(228, 133, 25);"></i>
          <i *ngIf="transportMenu" (click)="toggleFlag('transport',transportMenu)" class="fa fa-minus ml-auto" style="cursor: pointer; font-size:12px; color:rgb(228, 133, 25);"></i>
        </a> 
      </li>
      <span *ngIf="transportMenu">        
        <li>
          <a href="#" routerLink="/admin/vehicle" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Vehicle**</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/admin/busroute" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Bus Route**</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/admin/busroute" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Route Price Slab**</span>        
          </a>
        </li>
      </span>
      
      <!------------------AUTOMATIONS Menu Category----------------------->
      <!------------------AUTOMATIONS Menu Category----------------------->

      <li >
        <a routerLinkActive="active">
          <i class="fas fa-user-cog" style="font-size:20px"></i>
          <span style="cursor: pointer;" (click)="toggleFlag('test_exam',testMenu)">Online Examination**</span>
          <i *ngIf="!testMenu" (click)="toggleFlag('test_exam',testMenu)" class="fa fa-plus ml-auto" style="cursor: pointer; font-size:12px; color:rgb(228, 133, 25);"></i>
          <i *ngIf="testMenu" (click)="toggleFlag('test_exam',testMenu)" class="fa fa-minus ml-auto" style="cursor: pointer; font-size:12px; color:rgb(228, 133, 25);"></i>
        </a> 
      </li>
      <span *ngIf="testMenu">        
        <li>
          <a href="#" routerLink="/admin/questionbank" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Question Bank</span>        
          </a>
        </li>        
        <li>
          <a href="#" routerLink="/admin/onlineexam" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Online Exam</span>        
          </a>
        </li> 
        <li>
          <a href="#" routerLink="/user/tracker" routerLinkActive="active">
            &nbsp;&nbsp;<i class="fas fa-shipping-fast" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Offline Exam</span>         
          </a>
        </li> 
        <li>
          <a href="#" routerLink="/user/tracker" routerLinkActive="active">
            &nbsp;&nbsp;<i class="fas fa-shipping-fast" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Govt Exam Master</span>         
          </a>
        </li>        
        <li>
          <a href="#" routerLink="/user/tracker" routerLinkActive="active">
            &nbsp;&nbsp;<i class="fa fa-cog" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Score & Analytics</span>         
          </a>
        </li>
      </span>
      <!------------------Notificaton Menu Category----------------------->
      <li >
        <a routerLinkActive="active">
          <i class="fas fa-user-cog" style="font-size:20px"></i>
          <span style="cursor: pointer;" (click)="toggleFlag('notification',notificationMenu)">Communications*</span>
          <i *ngIf="!notificationMenu" (click)="toggleFlag('notification',notificationMenu)" class="fa fa-plus ml-auto" style="cursor: pointer; font-size:12px; color:rgb(228, 133, 25);"></i>
          <i *ngIf="notificationMenu" (click)="toggleFlag('notification',notificationMenu)" class="fa fa-minus ml-auto" style="cursor: pointer; font-size:12px; color:rgb(228, 133, 25);"></i>
        </a> 
      </li>
      <span *ngIf="notificationMenu">        
        <li>
          <a href="#" routerLink="/admin/notice" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Notices**</span>        
          </a>
        </li>        
        <li>
          <a href="#" routerLink="/user/tracker" routerLinkActive="active">
            &nbsp;&nbsp;<i class="fas fa-shipping-fast" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Send Mail</span>         
          </a>
        </li>        
        <li>
          <a href="#" routerLink="/user/tracker" routerLinkActive="active">
            &nbsp;&nbsp;<i class="fa fa-cog" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Send SMS</span>         
          </a>
        </li>
        <li>
          <a href="#" routerLink="/user/leads" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Notification Template</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/user/leads" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">SMS Template</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/user/leads" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Email Template</span>        
          </a>
        </li>
      </span>     
      <!------------------AUTOMATIONS Menu Category----------------------->
      <li >
        <a routerLinkActive="active">
          <i class="fas fa-user-cog" style="font-size:20px"></i>
          <span style="cursor: pointer;" (click)="toggleFlag('staff',staffMenu)">Human Resource</span>
          <i *ngIf="!staffMenu" (click)="toggleFlag('staff',staffMenu)" class="fa fa-plus ml-auto" style="cursor: pointer; font-size:12px; color:rgb(228, 133, 25);"></i>
          <i *ngIf="staffMenu" (click)="toggleFlag('staff',staffMenu)" class="fa fa-minus ml-auto" style="cursor: pointer; font-size:12px; color:rgb(228, 133, 25);"></i>
        </a> 
      </li>
      <span *ngIf="staffMenu">        
        <li>
          <a href="#" routerLink="/admin/staff" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Staff Directory</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/admin/staff/addStaff" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Add Staff</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/user/tracker" routerLinkActive="active">
            &nbsp;&nbsp;<i class="fa fa-cog" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Payroll</span>         
          </a>
        </li>
        <li>
          <a href="#" routerLink="/admin/department" routerLinkActive="active">
            &nbsp;&nbsp;<i class="fa fa-cog" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Department**</span>         
          </a>
        </li>
        <li>
          <a href="#" routerLink="/admin/designation" routerLinkActive="active">
            &nbsp;&nbsp;<i class="fa fa-cog" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Designation**</span>         
          </a>
        </li>
        <li>
          <a href="#" routerLink="/admin/leavetype" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Leave Type**</span>        
          </a>
        </li> 
        <li>
          <a href="#" routerLink="/admin/leavetype/setup" routerLinkActive="active">
            &nbsp;&nbsp;<i class="fa fa-cog" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Leave Master**</span>         
          </a>
        </li>
        <li>
          <a href="#" routerLink="/admin/staff/leave" routerLinkActive="active">
            &nbsp;&nbsp;<i class="fa fa-cog" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Leave Report**</span>         
          </a>
        </li>
        <li>
          <a href="#" routerLink="/admin/staff/leave/history" routerLinkActive="active">
            &nbsp;&nbsp;<i class="fa fa-cog" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Leave Approval**</span>         
          </a>
        </li>
        <li>
          <a href="#" routerLink="/admin/staff/attendance" routerLinkActive="active">
            &nbsp;&nbsp;<i class="fa fa-cog" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Attendance**</span>         
          </a>
        </li>
        <li>
          <a href="#" routerLink="/admin/staff/attendance/report" routerLinkActive="active">
            &nbsp;&nbsp;<i class="fa fa-cog" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Attendance Report**</span>         
          </a>
        </li>
      </span>
      <!------------------AUTOMATIONS Menu Category----------------------->  
      <li >
        <a href="#" routerLink="/admin/parent" routerLinkActive="active">
          <i class="fa fa-tv" style="font-size:18px"></i>
          <span>Parents</span>         
        </a>
      </li>
      <!------------------AUTOMATIONS Menu Category----------------------->
      
      <li >
        <a routerLinkActive="active">
          <i class="fas fa-user-cog" style="font-size:20px"></i>
          <span style="cursor: pointer;" (click)="toggleFlag('accounting',accountingMenu)">Accounting</span>
          <i *ngIf="!accountingMenu" (click)="toggleFlag('accounting',accountingMenu)" class="fa fa-plus ml-auto" style="cursor: pointer; font-size:12px; color:rgb(228, 133, 25);"></i>
          <i *ngIf="accountingMenu" (click)="toggleFlag('accounting',accountingMenu)" class="fa fa-minus ml-auto" style="cursor: pointer; font-size:12px; color:rgb(228, 133, 25);"></i>
        </a> 
      </li>
      <span *ngIf="accountingMenu">        
        <li>
          <a href="#" routerLink="/user/leads" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Income Head</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/user/leads" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Expense Head</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/user/leads" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Add Income</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/user/leads" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Bank Account</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/user/leads" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Add Vendor</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/user/leads" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Add Expense</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/user/leads" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Cash Deposit/Withdraw</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/user/leads" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Income & Expense</span>        
          </a>
        </li>        
      </span>
      
      <!------------------AUTOMATIONS Menu Category----------------------->
      <li >
        <a href="#" routerLink="/user/sample" routerLinkActive="active">
          <i class="fa fa-tv" style="font-size:18px"></i>
          <span>GMeet Live Class</span>         
        </a>
      </li>
      <li >
        <a href="#" routerLink="/user/sample" routerLinkActive="active">
          <i class="fa fa-tv" style="font-size:18px"></i>
          <span>Zoom Live Class</span>         
        </a>
      </li>
      <li >
        <a href="#" routerLink="/user/sample" routerLinkActive="active">
          <i class="fa fa-tv" style="font-size:18px"></i>
          <span>Study Materials</span>         
        </a>
      </li>
      <li >
        <a href="#" routerLink="/user/sample" routerLinkActive="active">
          <i class="fa fa-tv" style="font-size:18px"></i>
          <span>Promo Codes</span>         
        </a>
      </li>
      <li >
        <a href="#" routerLink="/user/sample" routerLinkActive="active">
          <i class="fa fa-tv" style="font-size:18px"></i>
          <span>Event Gallery</span>         
        </a>
      </li>

      <!------------------AUTOMATIONS Menu Category----------------------->

      <li >
        <a routerLinkActive="active">
          <i class="fas fa-user-cog" style="font-size:20px"></i>
          <span style="cursor: pointer;" (click)="toggleFlag('report',reportMenu)">Reports</span>
          <i *ngIf="!reportMenu" (click)="toggleFlag('report',reportMenu)" class="fa fa-plus ml-auto" style="cursor: pointer; font-size:12px; color:rgb(228, 133, 25);"></i>
          <i *ngIf="reportMenu" (click)="toggleFlag('report',reportMenu)" class="fa fa-minus ml-auto" style="cursor: pointer; font-size:12px; color:rgb(228, 133, 25);"></i>
        </a> 
      </li>
      <span *ngIf="reportMenu">        
        <li>
          <a href="#" routerLink="/user/leads" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Fee Payment Report</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/user/leads" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Student Performance</span>        
          </a>
        </li> 
        <li>
          <a href="#" routerLink="/user/leads" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Student Retention</span>        
          </a>
        </li>
        <li>
          <a href="#" routerLink="/user/leads" routerLinkActive="active">
            &nbsp;&nbsp;&nbsp;<i class="fas fa-tasks" style="font-size:17px"></i>
            <span style="color:rgb(228, 133, 25);">Teacher Retention</span>        
          </a>
        </li>   
      </span>
      <!------------------AUTOMATIONS Menu Category----------------------->
    </ul>
    
    <!--<div class="menu-header mt-4 mb-4">
      Github Repo
    </div>
    <div class="view-source mb-3">
      <a href="https://github.com/azouaoui-med/lightning-admin-angular" target="_blank" class="btn btn-block btn-lightning rounded-0">View Source</a>
    </div>  --> 
  </nav>
</aside>