// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  env: 'dev',
  settings: {
    backend: "api.myapp.com",
    otherurl: "other_url"
  },
  apiBase: 'https://schoolapi.nexsharp.in/api',
  authBase: 'https://auth.nexsharp.in',
  //redirectURI: 'http://194.238.19.142:7001',

  clientLogoUrl: 'https://auth.nexsharp.in/api/client/getLogo/',  
  authorizeAPI: '/oauth/authorize',
  getTokenAPI: '/oauth/token',
  //clientId: 'tSubnlTtQOFhm4YM',// oauth2-jwt-client, tSubnlTtQOFhm4YM 
  //clientName: 'TestClient',
  authUserInfoAPI: '/api/users/me',
  //userInfoAPI: '/profile/username',
  userInfoAPI: '/api/user',
  
  configFile: 'assets/config/config.json', //TODO- to read from local drive
  configPublicFile: 'https://jsonplaceholder.typicode.com/todos/1',
  configClient: 'https://auth.nexsharp.in/api/clientinfo',
  defaultTenant: 'dps',
  
  sessionTimeOut: 120000, // in millisecond
  sessionProgressBarTime: 30000, // in ms

  dtOnlyFormatDefault:'yyyy-MM-dd',
  dtFormatDefault:'dd/MM/yyyy HH:mm:ss', //dd/MM/yyyy hh:mm:ss aa
  //{{invoice.dueDate | date}}  results like - 'Dec 21, 2022'
  //Below are used only for showing in default section of profile..
  hrFormatDefault:'24 Hr', 
  timezoneDefault:'UTC', 
  currencyDefault:'INR',

  secret_store: 'secret_store',
  local_store: 'LOCAL',
  cloud_store: 'CLOUD',

  dayList: [{ id: 1, name: "Monday" },{ id: 2, name: "Tuesday" },{ id: 3, name: "Wednesday" }, { id: 4, name: "Thursday" }, { id: 5, name: "Friday" },],
  secondsList: [{ id: 15, name: "15" },{ id: 30, name: "30" },{ id: 50, name: "50" }],
  minutesList: [{ id: 2, name: "2" },{ id: 15, name: "15" },{ id: 30, name: "30" },{ id: 45, name: "45" }],
  hoursList: [{ id: 6, name: "6" },{ id: 7, name: "7" },{ id: 18, name: "18" },{ id: 19, name: "19" }],
  dayNumberList: [{ id: 1, name: "1" },{ id: 2, name: "2" },{ id: 3, name: "3" }, { id: 4, name: "4" }, { id: 5, name: "5" },{ id: 6, name: "6" },{ id: 7, name: "7" },{ id: 8, name: "8" },{ id: 9, name: "9" },{ id: 10, name: "10" },{ id: 15, name: "15" },{ id: 20, name: "20" },{ id: 25, name: "25" },{ id: 30, name: "30" }],
  numberList: [{ id: 1, name: "1" },{ id: 2, name: "2" },{ id: 3, name: "3" }, { id: 4, name: "4" }, { id: 5, name: "5" },],
  sequenceList: [{ id: 0, name: "NA" },{ id: 1, name: "1" },{ id: 2, name: "2" },{ id: 3, name: "3" }, { id: 4, name: "4" }, { id: 5, name: "5" },],
  secretLocationList: [{ id: 'NA', name: "NA" },{ id: 'LOCAL', name: "LOCAL" },{ id: 'CLOUD', name: "CLOUD"},],
  softwareList : [
    { id: 'nssm_32bit', name: "nssm_32bit" },
    { id: 'nssm_64bit', name: "nssm_64bit" },
    { id: 'chrome_driver', name: "chrome_driver" },
    { id: 'nexrpa_socket_win', name: "nexrpa_socket_win" },
    { id: 'nexrpa_socket_linux', name: "nexrpa_socket_linux"},
    { id: 'nexrpa_socket_mac', name: "nexrpa_socket_mac"},
    { id: 'nexrpa_engine', name: "nexrpa_engine" },
    { id: 'nexrpa_helper', name: "nexrpa_helper" },
    { id: 'nexrpa_client_ui', name: "nexrpa_client_ui" },
    { id: 'nexrpa_client_image', name: "nexrpa_client_image" },
    { id: 'sample_bot', name: "sample_bot" },
  ],
  softwareVerList: [
    { id: 'latest', name: "latest" },
    { id: '1.0', name: "1.0" },
    { id: '1.1', name: "1.1" },
    { id: '1.2', name: "1.2" },
  ],
  osList: [
    { id: 'win', name: "Windows" },
    { id: 'linux', name: "Linux" },
    { id: 'mac', name: "Mac OS" },
    { id: 'NA', name: "NA" },
  ],
  userTypeList: [
    { id: 'Admin', name: "Admin" },
    { id: 'System', name: "System" },
    { id: 'User', name: "User" },
  ],
  countryList : [
    { id: 'IN', name: "India" },
    { id: 'AU', name: "Australia" },
    { id: 'US', name: "USA" },
  ],
  currencyList : [
    { id: 'INR', name: "INR" },
    { id: 'USD', name: "USD" },
    { id: 'AUD', name: "AUD" }
  ],
  timezoneList : [
    { id: 'UTC', name: "UTC" },
    { id: 'IST', name: "IST" }
  ],
  dateFormatList : [
    { id: 'dd/MM/yyyy', name: "dd/MM/yyyy" },
    { id: 'MM/dd/yyyy', name: "MM/dd/yyyy" }
  ],
  roleList : [
    { id: 'USER', name: "USER" },
    { id: 'ADMIN', name: "ADMIN" },
    { id: 'MANAGER', name: "MANAGER" },
    { id: 'PARTNER', name: "PARTNER" }
  ],
  scopeList : [
    { id: 'read', name: "read" },
    { id: 'write', name: "write" }
  ],
  grantTypeList : [
    { id: 'authorization_code', name: "authorization_code" },
    { id: 'password', name: "password" },
    { id: 'client_credentials', name: "client_credentials" },
    { id: 'implicit', name: "implicit" },
    { id: 'refresh_token', name: "refresh_token" }
  ],
  roleManager : [
    { id: 'Manager', name: "Manager" }
  ],
  validityList : [
    { id: 7, name: "7 Days" },
    { id: 15, name: "15 Days" },
    { id: 30, name: "One Moth" },
    { id: 90, name: "Three Months" },
    { id: 180, name: "Six Months" },
    { id: 365, name: "One Year" }
  ],

  // These keys should be in sync with JobTypeEnum.java. because this gets saved in db from here & runtime compares with JobTypeEnum.java
  jobTypeList : [
    { id: 'PROCESS_RUN', name: "Process" },
    { id: 'BILLING', name: "Billing" },
    //{ id: 'INVOICE_GENERATOR', name: "Invoice Generator" },
    { id: 'DISABLE_USER', name: "Disable User" },
    { id: 'DISABLE_ORG', name: "Disable Organization" },
    { id: 'DELETE_ASSIST', name: "Delete Marked Assist" }, //which are marked as deleted
    { id: 'DELETE_WS', name: "Delete Marked Tenant" }, //which are marked as deleted
    { id: 'CLEAR_AUDIT_LOG', name: "Clear Audit Logs" }, // Keep for a specified time only
    { id: 'BLOCK_FREE_USER', name: "Block triel users" }, // may be 15 days
    //{ id: 'SYNC_RUN_LIMIT', name: "Block triel users" }, //// if run lumit for a plan has been updated by admin, then update same for the organizations who has subscribed to this plan
  ],
  pricePlanList : [
    { id: 'FREE_PLAN', name: "Free" },
    { id: 'STARTER', name: "Starter" },
    { id: 'PREMIUM', name: "Premium" },
    { id: 'ENTERPRISE', name: "Enterprise" },
  ],

  gitRepoList: [{ id: "http://localhost:7990/scm/dem/demo_app.git", name: "dem/demo_app" },
                { id: 'https://github.com/nilagithub/nilatestweb.git', name: "nilagithub/nilatestweb" }],
  //branch is dynamically fetched by repo
  
  SUCCESS: 'Success',
  FAILURE: 'Failure',
  STATUS_PENDING: 'STATUS_PENDING',
  STATUS_PROCESSING: 'PROCESSING',
  STATUS_COMPLETED: 'COMPLETED',

  socket_test_url: 'ws://nilatest:nilatest@localhost:8081/websocket',
  socket_auto_discon_time: 30000,// in ms i.e 15 mins= 9000000 ms

  chekck_process_run_interval: 15000, // api call for status check in every 'x' millisec
  chekck_process_run_times: 8, // number of api call 'y' for status check - total x*y=z ms 
  
  TICKET_TYPE_DEFECT: "Defect",
	TICKET_TYPE_SUPPORT: "Support",
  TICKET_OPEN:"OPEN",
	TICKET_INPROGRESS: "INPROGRESS",
	TICKET_RESOLVED: "RESOLVED",
	TICKET_CLOSED: "CLOSED",

  ticketTypeList: [
    { id: 'Defect', name: "Defect" },
    { id: 'Support', name: "Support" },
  ],
  ticketStatusList: [
    { id: 'OPEN', name: "OPEN" },
    { id: 'CLOSED', name: "CLOSED" },
    //{ id: 'INPROGRESS', name: "INPROGRESS" },
    { id: 'RESOLVED', name: "RESOLVED" },
  ],

  API_GRP_LIST: [
    { id: 'WORKZONE', name: "WORKZONE" },
    { id: 'MACHINE', name: "MACHINE" },
    { id: 'TASKBOT', name: "TASKBOT" },
    { id: 'PROCESS', name: "PROCESS" },
    { id: 'STEP', name: "STEP" },
    { id: 'ROBOASSIST', name: "ROBOASSIST" },
    { id: 'PROCESSRUN', name: "PROCESSRUN" },
    { id: 'STEPRUN', name: "STEPRUN" },
    { id: 'ASSISTRUN', name: "ASSISTRUN" },
  ],

  defaultPaginationSize: 10,
  itemCountPerPageList: [5, 10, 20, 2, 4],
  templateTypeList : [
    { id: 'Branch', name: "Branch" },
    { id: 'Board', name: "Board" },
    { id: 'Stream', name: "Stream" },
  ],

  ROLE_ADMIN: 'ADMIN',
  ROLE_STUDENT: 'STUDENT',
  ROLE_TEACHER: 'TEACHER',
  ROLE_PARENT: 'PARENT',
  ROLE_DRIVER: 'DRIVER',
  
  LEAVE_PENDING: 'PENDING',
  LEAVE_APPROVED: 'APPROVED',
  LEAVE_REJECTED: 'REJECTED',
  
  TRAN_FEE_CODE: 'TRANS',
  feeFrequenceyList: [
    { id: 'Once', name: "Once" },
    { id: 'Monthly', name: "Monthly" },
    { id: 'Quarterly', name: "Quarterly" },
    { id: 'Half Yearly', name: "Half Yearly" },
    { id: 'Yearly', name: "Yearly" },
    { id: 'Other', name: "Other" },
  ],
  noticeTypeList: [
    { id: 'Notice', name: "Notice" },
    { id: 'Event', name: "Event" },
    { id: 'News', name: "News" },
  ],
  
  questionTypeList: [
    { id: 'Single Choice', name: "Single Choice" },
    { id: 'Multiple Choice', name: "Multiple Choice" },
    { id: 'True/False', name: "True/False" },
    { id: 'Descriptive', name: "Descriptive" },
  ],
  questionLevelList: [
    { id: 'Low', name: "Low" },
    { id: 'Medium', name: "Medium" },
    { id: 'High', name: "High" },
  ],
  optionList: [
    { id: 'option1', name: "A" },
    { id: 'option2', name: "B" },
    { id: 'option3', name: "C" },
    { id: 'option4', name: "D" },
    { id: 'option5', name: "E" },
    { id: 'option6', name: "F" },
  ],
  trueFalse: [
    { id: true, name: true },
    { id: false, name: false },
  ],
  
};

export const SpinnerConfig = {
  bdColor: 'rgba(0, 0, 0, 0.8)',
  type: 'timer',
  size: 'medium',
  color: '#FF0000',
  fullScreen: true,
};

/*export const TRACKER_REFRESH_TIME = 60000;
export const UI_COMMON_DATE_FORMAT_FULL = "DD/MM/YYYY hh:mm A";
export const SCHEDULER_TIME = "HH:mm";
export const UI_COMMON_DATE_FORMAT_US = 'MMM-DD-YYYY';
export const UI_COMMON_DATE_FORMAT_US_FULL = 'MMM-DD-YYYY hh:mm A'
export const TIME_ZONE = 'America/Matamoros'*/

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
