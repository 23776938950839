<aside class="sidebar shadow-sm">

  <div class="sidebar-profile d-flex flex-column justify-content-center pt-2 pb-2">
    <!-- Profile with photo starts-->
    <!--<div class="picture-wrapper mb-2">
      <div class="user-picture m-auto">
        <img src="./assets/img/user.jpg" class="img-fluid" alt="">
      </div>
    </div>-->
    
    <div class="profile-details text-center pb-2">
      <p class="mb-0 name">{{userProfile.ownerName}}</p>
      <p class="mb-0 name" style="color:rgb(228, 133, 25);">{{userProfile.userRole}}</p>
      <span class="role">Last Login: {{userProfile.lastLogin | date: dateTimeFormat}}</span>
    </div>
    
    <!-- Profile with photo ends-->
    
  </div>
  <div class="sidebar-search pt-1 pb-1 pl-3 pr-3">
    <input type="search" class="form-control rounded-pill" name="search" placeholder="Search ...">
  </div>
  <nav class="sidebar-menu pt-1 pb-1">
    <!--<div class="menu-header mt-3 mb-2">
      Components
    </div>-->
    <ul class="menu-items">
      <li >
        <a href="#" routerLink="/student/dashboard" routerLinkActive="active">
          <!--<i class="fas fa-home" style="font-size:18px"></i>-->
          <i class="material-icons" style="font-size:20px">dashboard</i>
          <span >Dashboard</span>
          <!--<span class="badge badge-warning rounded-0 text-dark p-1">NEW</span>-->
        </a>
      </li>
      
    </ul>
    
    <!--<div class="menu-header mt-4 mb-4">
      Github Repo
    </div>
    <div class="view-source mb-3">
      <a href="https://github.com/azouaoui-med/lightning-admin-angular" target="_blank" class="btn btn-block btn-lightning rounded-0">View Source</a>
    </div>  --> 
  </nav>
</aside>