import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from './../../services/app.service';
import { UserService } from '../../shared/services/user.service';
import { User } from '../../models/user.model';
import { GenericModel } from '../../models/generic.model'
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-navbar-student',
  templateUrl: './navbar-student.component.html',
  styleUrls: ['./navbar-student.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavbarStudentComponent implements OnInit {
  
  constructor(
    private appService: AppService,
    private userService: UserService,) {
  }

  isCollapsed = true;
  orgName: string;
  userDetail: GenericModel<User>;
  userId: string;
  profilePicUrl: string = '';

  ngOnInit() {
    console.log('*****NavbarStudentComponent****');
    let hostname = window.location.hostname; // get tenant i.e subdomain from "subdomain.domain.com"
    let tenant = 'DemoTenant'
    if(hostname == 'localhost'){
      tenant = environment.defaultTenant
    }else{
      tenant= hostname.substring(0, hostname.indexOf('.'));
    }
    console.log(">>>>>Host Name:: "+hostname+' :tenant:>>>'+tenant);
    this.profilePicUrl = environment.clientLogoUrl+tenant+'?X-TenantID='+tenant;
    
  }

  toggleSidebarPin() {
    this.appService.toggleSidebarPin();
  }
  toggleSidebar() {
    this.appService.toggleSidebar();
  }
}
