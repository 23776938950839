import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
//import {ToastComponent} from "../../shared/toast/toast.component";
import * as moment from 'moment';
//import * as _ from 'lodash';
import { Cookie } from 'ng2-cookies';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AdminDashService } from './services/admin-dash.service';
import { AuthService } from '../../shared/services/auth.service';
import { TokenStorageService } from '../../shared/services/token-storage.service';
import { User } from '../../models/user.model';

@Component({
  selector: 'app-admin-dash',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashComponent implements OnInit {

  title = 'demo1';
  userProfile: User;

  constructor(private router: Router, private _http: HttpClient,
              private adminDashService: AdminDashService,
              private authService: AuthService, private tokenStorageService: TokenStorageService) { }

  ngOnInit() {
    console.log('--inside  admindash- ngOnInit----');
    //this.getUserProfile();
    this.saveUserData();
  }

  saveUserData(){
    console.log('>>>>>getUserProfile>>>>> ')
    const userData: User = {
      userName: this.tokenStorageService.getUser(),userRole: this.tokenStorageService.getUserRole(),
      clientName: this.tokenStorageService.getTenantName(), ownerName: this.tokenStorageService.getOwnerName()}

    this.adminDashService.saveUserData(userData).subscribe({
      next: (response: any) => {
        console.log(">>>saveUserData from service: "+JSON.stringify(response)); 
        this.userProfile =  response.data;            
      },
      error: (e) => {
        console.error(e);          
      }
    });
  }
  
  /*getUserProfile() {    
    console.log('>>>>>getUserProfile>>>>> ')
    this.adminDashService.getUserProfile().subscribe({
      next: (data: any) => {
        console.log(">>>getUserProfile from service: "+JSON.stringify(data)); 
        this.userProfile =   data            
      },
      error: (e) => {
        console.error(e);          
      }
    });
  }
  getUserData(){
    console.log('>>>>>getUserData- sidebar-admin>>>>> ')
    this.adminDashService.getUserData().subscribe({
      next: (response: any) => {
        console.log(">>>getUserData from service:sidebar aadmin- "+JSON.stringify(response));
        if(response.status == environment.SUCCESS){
          this.userProfile =  response.data;
        }else{
          // Get from cookie beacuse it happens for first time
          const userData: User = {
            userName: this.tokenStorageService.getUser(),userRole: this.tokenStorageService.getUserRole(),
            clientName: this.tokenStorageService.getTenantName(), ownerName: this.tokenStorageService.getOwnerName()}
          
          this.userProfile = userData;
          //this.toastNotifyService.showErrorWithTimeout(response.message, "Notification", 7000);
        }           
      },
      error: (e) => {
        console.error(e);          
      }
    });
  }*/

}