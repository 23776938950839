import { NgModule,APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA,} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
//import { AngularEditorModule} from '@kolkov/angular-editor';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { NgbModule, NgbModalModule,  NgbDatepickerModule, NgbAlertModule, } from '@ng-bootstrap/ng-bootstrap';

import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';

import { HomeLayoutComponent } from './layout/home/home-layout.component';
import { AdminLayoutComponent } from './layout/admin/admin-layout.component';
import { StudentLayoutComponent } from './layout/student/student-layout.component';
import { ParentLayoutComponent } from './layout/parent/parent-layout.component';
import { TeacherLayoutComponent } from './layout/teacher/teacher-layout.component';
import { SharedModule } from './shared/shared.module';

import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FooterComponent } from './components/footer/footer.component';

import {NavbarAdminComponent} from './components/navbar-admin/navbar-admin.component'
import {SidebarAdminComponent} from './components/sidebar-admin/sidebar-admin.component'

import {NavbarStudentComponent} from './components/navbar-student/navbar-student.component';
import {SidebarStudentComponent} from './components/sidebar-student/sidebar-student.component';

import {NavbarParentComponent} from './components/navbar-parent/navbar-parent.component';
import {SidebarParentComponent} from './components/sidebar-parent/sidebar-parent.component';

import {NavbarTeacherComponent} from './components/navbar-teacher/navbar-teacher.component';
import {SidebarTeacherComponent} from './components/sidebar-teacher/sidebar-teacher.component';

import { ConfigService } from './shared/services/config.service';
import { environment } from '../environments/environment';


export function initConfig(config: ConfigService) {
  console.log('------------initConfig-----------------')
  let hostname = window.location.hostname; // get tenant i.e subdomain from "subdomain.domain.com"
  let tenant = 'DemoTenant'
  if(hostname == 'localhost'){
    tenant = environment.defaultTenant
  }else{
    tenant= hostname.substring(0, hostname.indexOf('.'));
  }
  console.log(">>>>>Host Name:: "+hostname+' :tenant:>>>'+tenant);
  return () => config.load(environment.configClient+'/'+tenant);//configFile, configPublicFile
}

@NgModule({
  declarations: [
    AppComponent,
    HomeLayoutComponent,
    AdminLayoutComponent,
    StudentLayoutComponent,
    ParentLayoutComponent,
    TeacherLayoutComponent,
    
    NavbarComponent,
    SidebarComponent,
    FooterComponent,

    NavbarAdminComponent,
    SidebarAdminComponent,
    NavbarStudentComponent,
    SidebarStudentComponent,
    NavbarParentComponent,
    SidebarParentComponent,
    NavbarTeacherComponent,
    SidebarTeacherComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    //AngularEditorModule,
    CommonModule,
    BrowserAnimationsModule,
    CollapseModule.forRoot(),
    ToastrModule.forRoot({
      //timeOut: 150000, 
      closeButton: true,
      progressBar: true,
    }),
    NgxSpinnerModule,
    NgbModule,
    //NgbModalModule,  NgbDatepickerModule, NgbAlertModule,

    SharedModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [ConfigService],
      multi: true,
    },
  ], 
  bootstrap: [AppComponent]
})
export class AppModule { }
